import { Vue } from 'vue-class-component';
import { Action, Getter, Mutation, State, namespace } from 'vuex-class';
import { Shop } from '@/store/shop/types';
import * as messageModal from "@/store/message-modal.js"
import $ from "jquery";

const page = namespace('shop');
const customer = namespace('customer');
const streamPage = namespace('ppWebModels');
const modelAccount = namespace('modelAccount');

export default class ModelShopPage extends Vue {
	@page.State('shop') shop: Shop | undefined;

    @customer.Action('fetchUserData') getUserData: any;
    @customer.Getter('getUserData') userData: any;
    @customer.Action('addToBasket') addToBasket: any;
    @customer.Action('getBasket') getBasket: any;
    //@customer.Getter('basket') basket: any;

	//Страница стрима
    @streamPage.Action('getModelInfoByUsername') getModelInfoByUsername: any;
    @streamPage.Getter('getModelInfo') getModelInfo: any;

    //Магазин
    @modelAccount.Action('getShopCategories') getShopCategories: any;
    @modelAccount.Action('getModelProducts') getModelProducts: any;
    @modelAccount.Getter('shopCategories') shopCategories: any;
    @modelAccount.Getter('modelProducts') modelProducts: any;

	selectedProduct : any = {};
    categories : any = [];

    currentSlide : number = 0;
    selectedCategory : any = null;

    get sliderShift() {
        return "margin-left: -" + this.currentSlide * 100 + "%";
    }

    AddToBasket(e : any, product : any, count : number)
    {
        let _this = this;

        let animationClone = $(e.target).clone();
        let startPos : any = $(e.target).offset();
        let targetPos : any = $('.js-basket-menu').offset();
        $('body').append(animationClone);
        animationClone.css('transition', '0');
        animationClone.css('position', 'absolute');
        animationClone.css('top', startPos.top);
        animationClone.css('left', startPos.left);
        animationClone.css('opacity', '1');

        setTimeout(function () {
            animationClone.css('transition', '500ms');
            animationClone.css('top', targetPos.top);
            animationClone.css('left', targetPos.left);
            animationClone.css('opacity', '0');
        }, 100);

        setTimeout(function () {
            animationClone.remove();
        }, 600);


        this.addToBasket({
            "id": product.id,
            "count": count
        }).then(function (response : any) {
            if (response.status == true) {
                _this.getBasket();
            } else {
                if (response.errors.length)
                    messageModal.Show("Ошибка", response.errors[0]);
            }
        });
    }

    ProductImage(product : any)
    {
        return "background-image: url('" + product.image + "')";
    }

    SlideToRight()
    {
        let slidesCount = $('.js-slides > *').length;
        if (this.currentSlide < this.GetModelProducts().length-1)
            this.currentSlide++;
    }

    SlideToLeft()
    {
        if (this.currentSlide > 0)
            this.currentSlide--;
    }

    ToSlide(num : number)
    {
        this.currentSlide = num;
    }

    SelectCategory(category : any) {
        for (let product of this.categories)
            product.class = "";

        if (!category)
        {
            this.selectedCategory = null;
            window.location.hash = "";
        }
        else
        {
            this.selectedCategory = category.id;

            this.selectedProduct = category;
            this.selectedProduct.class = "active";

            window.location.hash = "#"+category.id;
        }

        this.ToSlide(0);
	}

	GetModelProducts()
    {
        var _this = this;

        if (_this.selectedCategory)
        {
            return this.modelProducts.filter(function( obj : any ) {
                return obj.category_id == _this.selectedCategory;
            });
        }
        else
        {
            return this.modelProducts;
        }
    }

	mounted() {
        var _this = this;

        messageModal.Init();

        this.getUserData().then(function () {
            if (!_this.userData)
                messageModal.RegistrationOrLogin($('#js-modal-registration-or-login .js-text').attr('data-text-need-account'), $('#js-modal-registration-or-login .js-text-2').attr('data-text-need-account'), '/images/cart.png', true);
        });

        let categoryIndex : any = window.location.hash.substr(1);

        this.getModelInfoByUsername(this.$route.params['modelname']).then(function () {
            _this.getModelProducts(_this.getModelInfo.model_id).then(function () {
                _this.getShopCategories().then(function () {
                    for (let category of _this.shopCategories)
                    {
                        let data = {
                            id : category.id,
                            icon : category.image,
                            name : category.name,
                            class : (categoryIndex == category.id) ? 'active' : '',
                            visible : _this.modelProducts.some(function( obj : any ) {
                                return obj.category_id == category.id;
                            }),
                        };

                        _this.categories.push(data);
                    }

                    let findedCategory = _this.categories.filter(function( obj : any ) { return obj.id == categoryIndex; });
                    if (findedCategory.length && findedCategory[0].visible)
                        _this.SelectCategory(findedCategory[0]);
                });
            });
        });
	}
}
